<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="turmas.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Turma</th>
                        <th>Professor</th>
                        <th>Início</th>
                        <th>Conclusão</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(t, i) in turmas.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" :color="corAvatar(t.turma)">
                                <span class="white--text headline">{{t.turma.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="t.id_turma">
                            <router-link exact color="primary" v-if="permissao('turma_abrir')" :to="`/turma/gerenciar/${t.id_turma}`"><strong>{{t.turma}}</strong></router-link>
                            <strong v-else>{{t.turma}}</strong><br/>
                            <small><strong>ID:</strong> {{t.id_turma}}</small>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('usuario_abrir')" :to="`/usuario/gerenciar/${t.id_usuario_professor_responsavel}`">{{t.usuario_professor_responsavel}}</router-link>
                            <template v-else>{{t.usuario_professor_responsavel}}</template>
                        </td>
                        <td>{{dataPt(t.dt_inicio)}}</td>
                        <td>{{dataPt(t.dt_fim)}}</td>
                        <td>{{t.status}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="turmas.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import {mapState} from "vuex"
import axios from "axios"

export default {
    name: "TurmaListar",
    props : ['filtro', 'meu'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            turmas : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['apiUrl', 'paginationLimit'])
    },
    methods : {
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}turma/listar`, {
                pagina,
                limit       : this.paginationLimit,
                offset      : this.paginationLimit * pagina,
                busca       : this.filtro.busca || null,
                id_franquia : this.filtro.id_franquia || null
            }).then( (res) => {
                this.turmas = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
