<template>
    <v-row>
        <v-col class="pb-0" cols="12">
            <v-btn text to="/turma/cadastrar" v-if="permissao('turma_cadastrar')"><v-icon left>mdi-plus</v-icon>Cadastrar turma</v-btn>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2 hidden-xs-only">Filtrar</v-toolbar-title>
                    <v-spacer class="hidden-xs-only"/>
                    <v-row>
                        <v-col v-if="usuarioSessao.id_grupo == 1 || usuarioSessao.id_grupo == 2" cols="6"><SelectFranquia dense hide-details label="* Franquia:" flat outlined v-model="filtro.id_franquia" /></v-col>
                        <v-col cols="6"><v-text-field label="Buscar:" hide-details dense flat outlined  v-model="filtro.busca" /></v-col>
                    </v-row>
                </v-toolbar>
                <TurmaListar :filtro="filtro" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import TurmaListar from "@/Views/Turma/Component/TurmaListar"
    import {mapState} from 'vuex'
    import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

    export default {
        name: "Turma",
        components: {SelectFranquia, TurmaListar},
        data() {
            return {
                filtro : {}
            }
        },
        computed : {
            ...mapState(['usuarioSessao'])
        }
    }
</script>

<style scoped>

</style>
